<template>
  <div class="inWrap">
    <!-- 是否视频 -->
    <div class="common-card" v-if="list.articleType == 3">
      <div class="spec-block-2" @click="viewDetail(list.id, list.articleType, list.content)">
        <div class="title">{{ list.title }}</div>
        <div class="video-img-box" :style="{height:(videoWidth-26)/16*9 + 'px'}">
          <img v-if="list.firstPicture" :src="list.firstPicture" alt="">
          <div class="video-play-icon">
              <span class="iconfont">&#xe6a4;</span>
          </div>
          <div class="video-time">
              {{ list.time | videoDealTime }}
          </div>
        </div>
        <div class="spec">
            <div class="author">{{ list.author.nickname }}·{{ list.createTime | compareDate }}</div>
            <div class="comment" v-show="list.comments > 0">{{ list.comments }} 评论</div>
        </div>
      </div>
    </div>
    <!-- 显示长图，或者H5 -->
    <div class="imgs" v-else-if="list.layoutType === 4 && list.articleType === 7">
      <img :src="list.content" alt="" />
    </div>
    <div class="common-card" v-else>
      <!-- 一图显示 -->
      <div class="spec-block-1" v-if="list.layoutType != 3 && list.pictures.length < 3 && list.pictures.length !== 0" @click="viewDetail(list.id, list.articleType,list.content)">
        <div class="one-picture">
          <div class="one-picture-left">
            <div class="title" v-if="[5,8].includes(list.articleType)"><i class="iconfont ico">&#xe698;</i>{{ list.title }}</div>
            <div class="title" v-else>{{ list.title }}</div>
            <div class="spec">
              <!-- <div class="spec-ico" v-if="list.articleType === 5"><i class="iconfont ico">&#xe68f;</i></div> -->
              <div class="author">{{ list.author.nickname }}·{{ list.createTime | compareDate }}</div>
              <div class="comment" v-show="list.comments > 0">{{ list.comments }} 评论</div>
            </div>
          </div>
          <div class="picture" v-if="list.pictures.length !== 0 && list.pictures[0] !== ''" :style="{ background: 'url(' + list.pictures[0] + ') no-repeat center center', 'background-size': 'cover' }"></div>
        </div>
      </div>
      <!-- 无图/三图/大图显示 -->
      <div class="spec-block-2" v-if="list.layoutType === 3 || list.pictures.length >= 3 || list.pictures.length === 0" @click="viewDetail(list.id, list.articleType, list.content)">
        <div class="title" v-if="[5,8].includes(list.articleType)"><i class="iconfont ico">&#xe698;</i>{{ list.title }}</div>
        <div class="title" v-else>{{ list.title }}</div>
        <div class="banner" v-if="list.layoutType === 3" :style="{ background: 'url(' + list.firstPicture + ') no-repeat center center', 'background-size': 'cover' }"></div>
        <div class="three" v-if="list.layoutType != 3 && list.pictures.length >= 3">
          <div class="three-pic" v-for="item in list.pictures" :key="item.id" :style="{ background: 'url(' + item + ') no-repeat center center', 'background-size': 'cover' }"></div>
        </div>
        <div class="spec">
          <!-- <div class="spec-ico" v-if="list.articleType === 5"><i class="iconfont ico">&#xe68f;</i></div> -->
          <div class="author">{{ list.author.nickname }}·{{ list.createTime | compareDate }}</div>
          <div class="comment" v-show="list.comments > 0">{{ list.comments }} 评论</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoWidth: document.getElementById('app').offsetWidth,
    }
  },
  props: ['list','index', 'channelID'],
  filters: {
    dateFormat(oldData) {
      let datelist = oldData.split(' ')
      let datelist2 = datelist[0].split('-')
      const dt = new Date()
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      if (y == datelist2[0] && m == datelist2[1]) {
        if (d - datelist2[2] == 0) {
          return '今天 ' + datelist[1]
        } else if (d - datelist2[2] == 1) {
          return '昨天 ' + datelist[1]
        } else {
          return oldData
        }
      } else {
        return oldData
      }
    }
  },
  methods: {
    viewDetail(id, articleType, content) {
      this.$emit('toDetail', id, articleType, content)
    },
  },
}
</script>

<style  scoped>
.ico {
  font-size: 14.8px;
  color: #F7321C;
  margin-right: 3px;
  line-height: 1;
}
</style>
<style lang="less">
.inWrap {
  .imgs {
    box-sizing: border-box;
    padding: 15px 13px 0 13px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .common-card {
    .spec-block-1,
    .spec-block-2 {
      border-bottom: 1px solid #f6f6f6;
      padding: 13px;
    }
    .title {
      font-size: 17px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #242831;
      .p1 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .p2 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        color: #70767d;
        max-height: 60px;
        font-size: 14px;
        margin-top: 5px;
      }
    }
    .spec {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      color: #8c94a3;
      margin-top: 12px;
      /* .spec-ico {
      margin-right: 5px;
      .ico {
        font-size: 12px;
        color: #F7321C;
      }
    } */
      .author {
        margin-right: 8px;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        color: #8c94a3;
      }
    }
    .spec-block-1 {
      .one-picture {
        display: flex;
        justify-content: space-between;
        .one-picture-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          max-height: 102px;
          .title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            word-break: break-all;// 纯数字不换行
          }
        }
        .picture {
          flex-shrink: 0;
          width: 114px;
          height: 78px;
          margin-left: 20px;
          border-radius: 4px;
        }
      }
    }
    .spec-block-2 {
      .title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .banner {
        width: 100%;
        height: 195px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
        margin-top: 13px;
      }
      .three {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 78px;
        overflow: hidden;
        margin-top: 10px;
        border-radius: 4px;
        .three-pic {
          margin-right: 1px;
          flex-shrink: 0;
          width: 33.3%;
          height: 78px;
        }
      }
    }
    .video-img-box{
      position: relative;
      overflow: hidden;
      margin-top: 10px;
      border-radius: 4px;
      background: black;
      img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .video-play-icon{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: rgba(0,0,0,0.5);
        transform: translate(-50%, -50%);
        .iconfont{
          position: absolute;
          left: 50%;
          top: 50%;
          color: white;
          transform: translate(-50%, -50%);
        }
      }
      .video-time{
        position: absolute;
        right: 10px;
        bottom: 10px;
        border-radius: 10px;
        color: white;
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
